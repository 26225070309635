import Vue from 'vue'
import VueRouter from 'vue-router'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/home/Home.vue'),
      meta: {
        needsAuthentication: true,
        pageTitle: 'Home',        
      },
    },
    {
      path: '/help-center',
      name: 'help-center',
      component: () => import('@/views/home/HelpCenter.vue'),
      meta: {
        needsAuthentication: true,
        pageTitle: 'Centro de Ayuda',        
      },
    },
    {
      path: '/public-top-projects',
      name: 'public-top-projects',
      component: () => import('@/views/projects/PublicTopProjects.vue'),
      meta: {
        layout: 'full',       
      },
    },
    {
      path: '/public-top-cattle-offerings',
      name: 'public-top-cattle-offerings',
      component: () => import('@/views/projects/PublicTopCattleOfferings.vue'),
      meta: {
        layout: 'full',       
      },
    },
    {
      path: '/public-project-details',
      name: 'public-project-details',
      component: () => import('@/views/projects/PublicProjectDetails.vue'),
      meta: {
        pageTitle: 'Detalles de Proyecto',        
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/home/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/home/Register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/home/ForgotPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/privacy-policy',
      name: 'privacy-policy',
      component: () => import('@/views/home/PrivacyPolicy.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/terms-and-conditions',
      name: 'terms-and-conditions',
      component: () => import('@/views/home/TermsAndConditions.vue'),
      meta: {
        layout: 'full',
      },
    },    
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
    {
      path: '/edit-account',
      name: 'edit-account',
      component: () => import('@/views/home/EditAccount.vue'),
      meta: {
        needsAuthentication: true,
        pageTitle: 'Editar Cuenta',        
      },
    },
    {
      path: '/create-edit-project',
      name: 'create-edit-project',
      component: () => import('@/views/producer/CreateEditProject.vue'),
      meta: {
        needsAuthentication: true,
        pageTitle: 'Crear/Editar Proyecto',        
      },
    },
    {
      path: '/edit-producer-profile',
      name: 'edit-producer-profile',
      component: () => import('@/views/producer/EditProducerProfile.vue'),
      meta: {
        needsAuthentication: true,
        pageTitle: 'Editar Información Productor',        
      },
    },
    {
      path: '/producer-public-profile',
      name: 'producer-public-profile',
      component: () => import('@/views/producer/ProducerPublicProfile.vue'),
      meta: {
        pageTitle: 'Perfil Publico Productor',        
      },
    },
    {
      path: '/my-projects',
      name: 'my-projects',
      component: () => import('@/views/producer/MyProjects.vue'),
      meta: {
        needsAuthentication: true,
        pageTitle: 'Mis Proyectos',        
      },
    },
    {
      path: '/invest-in-project',
      name: 'invest-in-project',
      component: () => import('@/views/investor/InvestInProject.vue'),
      meta: {
        needsAuthentication: true,
        pageTitle: 'Participar en Proyecto',        
      },
    },
    {
      path: '/invest-in-project-forced',
      name: 'invest-in-project-forced',
      component: () => import('@/views/investor/InvestInProjectForced.vue'),
      meta: {
        needsAuthentication: true,
        pageTitle: 'Participar en Proyecto forzado',        
      },
    },
    {
      path: '/payment-response',
      name: 'payment-response',
      component: () => import('@/views/investor/PaymentResponse.vue'),
      meta: {
        needsAuthentication: true,
        pageTitle: 'Respuesta de Pago',        
      },
    },
    {
      path: '/edit-investor-profile',
      name: 'edit-investor-profile',
      component: () => import('@/views/investor/EditInvestorProfile.vue'),
      meta: {
        needsAuthentication: true,
        pageTitle: 'Editar Información NeoGanadero',        
      },
    },
    {
      path: '/edit-cattle-seller-profile',
      name: 'edit-cattle-seller-profile',
      component: () => import('@/views/cattleSeller/EditCattleSellerProfile.vue'),
      meta: {
        needsAuthentication: true,
        pageTitle: 'Editar Información Usuario',        
      },
    },
    {
      path: '/projects-and-investments',
      name: 'projects-and-investments',
      component: () => import('@/views/investor/ProjectsAndInvestments.vue'),
      meta: {
        needsAuthentication: true,
        pageTitle: 'Proyectos e Inversiones',        
      },
    },
    {
      path: '/my-investments',
      name: 'my-investments',
      component: () => import('@/views/investor/MyInvestments.vue'),
      meta: {
        needsAuthentication: true,
        pageTitle: 'Mis Inversiones',        
      },
    },
    {
      path: '/cattle-offerings',
      name: 'cattle-offerings',
      component: () => import('@/views/investor/CattleOfferings.vue'),
      meta: {
        needsAuthentication: false,
        pageTitle: 'Ofertas de Ganado',        
      },
    },
    {
      path: '/cattle-offering-details',
      name: 'cattle-offering-details',
      component: () => import('@/views/projects/CattleOfferingDetails.vue'),
      meta: {
        needsAuthentication: false,
        pageTitle: 'Oferta de Ganado',        
      },
    },
    {
      path: '/project-progress',
      name: 'project-progress',
      component: () => import('@/views/investor/ProjectProgress.vue'),
      meta: {
        needsAuthentication: true,
        pageTitle: 'Seguimiento de Proyecto',        
      },
    },
    {
      path: '/search-projects',
      name: 'search-projects',
      component: () => import('@/views/investor/SearchProjects.vue'),
      meta: {
        pageTitle: 'Buscar Proyectos',        
      },
    },
    {
      path: '/projects-to-aprove',
      name: 'projects-to-aprove',
      component: () => import('@/views/admin/ProjectsToApprove.vue'),
      meta: {
        needsAuthentication: true,
        pageTitle: 'Gestión de Proyectos',        
      },
    },
    {
      path: '/investments-to-aprove',
      name: 'investments-to-aprove',
      component: () => import('@/views/admin/InvestmentsToApprove.vue'),
      meta: {
        needsAuthentication: true,
        pageTitle: 'Gestión de Inversiones',        
      },
    },
    {
      path: '/users-management',
      name: 'users-management',
      component: () => import('@/views/admin/UsersManagement.vue'),
      meta: {
        needsAuthentication: true,
        pageTitle: 'Gestión de Usuarios',        
      },
    },
    {
      path: '/money-requests-to-approve',
      name: 'money-requests-to-approve',
      component: () => import('@/views/admin/MoneyRequestsToApprove.vue'),
      meta: {
        needsAuthentication: true,
        pageTitle: 'Gestión de Transferencias',        
      },
    },
    {
    path: '/edit-investment',
    name: 'edit-investment',
    component: () => import('@/views/investor/EditInvestment.vue'),
    meta: {
      pageTitle: 'Editar Inversión',
      },
    },
    {
      path: '/confirm-email',
      name: 'confirm-email',
      component: () => import('@/views/home/ConfirmEmail.vue'),
      meta: {
        pageTitle: 'Confirmar Email',
        layout: 'full',
      }
    },
    {
      path: '/thank-you-page',
      name: 'thank-you-page',
      component: () => import('@/views/home/ThankYouPage.vue'),
      meta: {
        pageTitle: 'Thank You',
        layout: 'full',
      }
    },
    {
      path: '/featured-project',
      name: 'featured-project',
      component: () => import('@/views/home/FeaturedProject.vue'),
      meta: {
        pageTitle: 'Featured Project',
        layout: 'full',
      }
    },
    {
      path: '/accept-terms-and-conditions',
      name: 'accept-terms-and-conditions',
      component: () => import('@/views/home/AcceptTermsAndConditions.vue'),
      meta: {
        pageTitle: 'Aceptar Términos y Condiciones',
        layout: 'full',
      }
    },
    {
      path: '/get-metrics',
      name: 'get-metrics',
      component: () => import('@/views/admin/GetMetrics.vue'),
      meta: {
        needsAuthentication: true,
        pageTitle: 'Métricas',  
      }
    },
    {
      path: '/control-board',
      name: 'control-board',
      component: () => import('@/views/admin/ControlBoard.vue'),
      meta: {
        needsAuthentication: true,
        pageTitle: 'Tablero de Control',  
      }
    },
    {
      path: '/cattle-offerings-management',
      name: 'cattle-offerings-management',
      component: () => import('@/views/admin/CattleOfferingsManagement.vue'),
      meta: {
        needsAuthentication: true,
        pageTitle: 'Ofertas de Ganado',  
      }
    },
    {
      path: '/my-cattle-offerings',
      name: 'my-cattle-offerings',
      component: () => import('@/views/cattleSeller/MyCattleOfferings.vue'),
      meta: {
        needsAuthentication: true,
        pageTitle: 'Mis Ofertas de Ganado',  
      }
    },
    {
      path: '/profile-definition',
      name: 'profile-definition',
      component: () => import('@/views/investor/ProfileDefinition.vue'),
      meta: {
        needsAuthentication: true,
        pageTitle: 'Definición Perfil',  
      }
    },
    {
      path: '/follow-up-project',
      name: 'follow-up-project',
      component: () => import('@/views/projects/FollowUpProject.vue'),
      meta: {        
        needsAuthentication: true,
        pageTitle: 'Seguimiento Proyecto',  
      }
    },
    {
      path: '/project-weights',
      name: 'project-weights',
      component: () => import('@/views/projects/ProjectWeights.vue'),
      meta: {
        needsAuthentication: true,
        pageTitle: 'Pesos Proyecto',  
      }
    },
    {
      path: '/project-events',
      name: 'project-events',
      component: () => import('@/views/projects/ProjectEvents.vue'),
      meta: {
        needsAuthentication: true,
        pageTitle: 'Eventos Proyecto',  
      }
    },
    {
      path: '/project-settlement',
      name: 'project-settlement',
      component: () => import('@/views/projects/ProjectSettlement.vue'),
      meta: {
        needsAuthentication: true,
        pageTitle: 'Liquidación de Proyecto',        
      },
    },
    {
      path: '/accept-investment-gift',
      name: 'accept-investment-gift',
      component: () => import('@/views/investor/AcceptInvestmentGift.vue'),
      meta: {
        pageTitle: 'Regalo Sosty',
        
      }
    },
    {
      path: '/project-liquidations',
      name: 'project-liquidations',
      component: () => import('@/views/admin/ProjectLiquidations.vue'),
      meta: {
        needsAuthentication: true,
        pageTitle: 'Liquidaciones del Proyecto',        
      },
    },
    {
      path: '/project-investments-liquidations',
      name: 'project-investments-liquidations',
      component: () => import('@/views/admin/ProjectInvestmentsLiquidations.vue'),
      meta: {
        needsAuthentication: true,
        pageTitle: 'Liquidaciones del Proyecto',        
      },
    },
    {
      path: '/my-carbon-farms',
      name: 'my-carbon-farms',
      component: () => import('@/views/carbonProducer/MyCarbonFarms.vue'),
      meta: {
        needsAuthentication: false,
        pageTitle: 'Mis fincas de carbono',  
      }
    },
    {
      path: '/calendar-documents-carbon-farm',
      name: 'calendar-documents-carbon-farm',
      component: () => import('@/views/carbonProducer/CalendarDocumentsCarbonFarm.vue'),
      meta: {
        needsAuthentication: false,
        pageTitle: 'Calendario de documentos',  
      }
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('@/views/carbonProducer/Dashboard.vue'),
      meta: {
        needsAuthentication: false,
        pageTitle: 'Dashboard',  
      }
    },
    {
      path: '/producers-management',
      name: 'producers-management',
      component: () => import('@/views/carbonAdmin/ProducersManagement.vue'),
      meta: {
        needsAuthentication: true,
        pageTitle: 'Productores de Carbono',  
      }
    },
    {
      path: '/farms-management',
      name: 'farms-management',
      component: () => import('@/views/carbonAdmin/FarmsManagement.vue'),
      meta: {
        needsAuthentication: true,
        pageTitle: 'Fincas de Carbono',  
      }
    },
    {
      path: '/register-metrics',
      name: 'register-metrics',
      component: () => import('@/views/carbonAdmin/RegisterMetrics.vue'),
      meta: {
        needsAuthentication: true,
        pageTitle: 'Capturas y emisiones',  
      }
    },
    {
      path: '/carbon-sales',
      name: 'carbon-sales',
      component: () => import('@/views/carbonAdmin/CarbonSales.vue'),
      meta: {
        needsAuthentication: true,
        pageTitle: 'Ventas Créditos',  
      }
    },
    {
      path: '/farm-event',
      name: 'farm-event',
      component: () => import('@/views/carbonAdmin/FarmEvent.vue'),
      meta: {
        needsAuthentication: true,
        pageTitle: 'Eventos Monitoreo',  
      }
    },
    {
      path: '/farms-checklist',
      name: 'farms-checklist',
      component: () => import('@/views/carbonAdmin/FarmsChecklist.vue'),
      meta: {
        needsAuthentication: true,
        pageTitle: 'Checklist',  
      }
    },
    {
      path: '/events-list',
      name: 'events-list',
      component: () => import('@/views/carbonAdmin/EventsList.vue'),
      meta: {
        needsAuthentication: true,
        pageTitle: 'Eventos de Monitoreo',  
      }
    },
    {
      path: '/calendar-documents-administration',
      name: 'calendar-documents-administration',
      component: () => import('@/views/carbonAdmin/CalendarDocumentsAdministration.vue'),
      meta: {
        needsAuthentication: true,
        pageTitle: 'Configuración Documentos',  
      }
    },
    {
      path: '/review-documents',
      name: 'review-documents',
      component: () => import('@/views/carbonAdmin/ReviewDocuments.vue'),
      meta: {
        needsAuthentication: true,
        pageTitle: 'Gestión Documentos',  
      }
    }
  ],
})


router.beforeEach((to, _, next) => {

  if(to.meta.needsAuthentication == true){    
    const isLoggedIn = isUserLoggedIn();
    if (isLoggedIn){   
      var userData = getUserData();
      if(userData.termsAndConditionsAccepted !== true)
        next({ name: 'accept-terms-and-conditions' }) 

      next();
    } else {      
      return next({ name: 'login' })
    }
  }

  return next()
})


export default router
