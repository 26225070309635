import Vue from 'vue'

// axios
import axios from 'axios'

var apiUrl = process.env.NODE_ENV === 'development' ? 'https://localhost:5001/api/' : 'https://sosty-api.azurewebsites.net/api/'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: apiUrl,
  timeout: 300000,
  // headers: {'X-Custom-Header': 'foobar'}
})

axiosIns.sostyApiUrl = apiUrl;

Vue.prototype.$http = axiosIns

export default axiosIns
