import useJwt from '@/auth/jwt/useJwt'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return localStorage.getItem('userData') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  return '/' 
}

export const getAbilitiesBasedOnUserType = userRole => {
  var abilities = [{ action:'all', subject:'Publico' }];
  switch(userRole) {
    case 'Productor':
      abilities.push({ action:'all', subject:'Productor' })
      break;
    case 'Inversionista':
      abilities.push({ action:'all', subject:'Inversionista' })
      break;
    case 'UsuarioSubasta':
      abilities.push({ action:'all', subject:'UsuarioSubasta' })
      break;
    case 'Admin':
    //   abilities.push({ action:'all', subject:'Productor' })
    //   abilities.push({ action:'all', subject:'Inversionista' })
      abilities.push({ action:'all', subject:'Admin' })
      abilities.push({ action:'all', subject:'Operador' })
      break;
    case 'ProductorInversionista':
      abilities.push({ action:'all', subject:'Productor' })
      abilities.push({ action:'all', subject:'Inversionista' })
      break;
    case 'Operador':
      abilities.push({ action:'all', subject:'Operador' })
      break;
    case 'ProductorCarbono':
      abilities.push({ action:'all', subject:'ProductorCarbono' })
      break;
    case 'AdminCarbono':
      abilities.push({ action:'all', subject:'AdminCarbono' })
      break;
    case 'AuditorCarbono':
      abilities.push({ action:'all', subject:'AuditorCarbono' })
      break;
  }
  return abilities;
}
